<template>
  <div class="card border-0 shadow-sm">
    <div class="card-header">
      <span>Email Templates</span>
      <button
        class="btn btn-sm btn-outline-primary float-end"
        @click.prevent="showAddTemplate"
      >
        <i class="far fa-plus me-1"></i>
        New Email Template
      </button>
    </div>
    <div class="card-body pt-0">
      <busy :visible="loading" />

      <div class="row">
        <div class="col">
          <div v-if="publicTemplates.length > 0">
            <table class="table table-hover">
              <thead class="thead-dark">
                <th scope="col">ID</th>
                <th scope="col">Name</th>
                <th scope="col">Type</th>
                <th scope="col"></th>
              </thead>
              <tbody>
                <tr v-for="template in publicTemplates">
                  <td>{{ template.id }}</td>
                  <td>{{ template.title }}</td>
                  <td class="text-capitalize">{{ template.type }}</td>
                  <td class="text-end">
                    <span v-if="!template.user_id" class="badge bg-primary"
                      >Swandoola Template</span
                    >
                    <button
                      v-if="template.user_id"
                      class="btn btn-light btn-sm ms-1"
                      @click.prevent="showEditTemplate(template)"
                    >
                      <i class="fa fa-edit text-primary"></i>
                    </button>
                    <button
                      class="btn btn-light btn-sm ms-1"
                      @click.prevent="showPreviewModal(template)"
                    >
                      <i class="fa fa-eye text-primary"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <p class="text-center">No email templates found.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["fetchTemplates"],
  data() {
    return {
      loading: false,
      publicTemplates: [],
    };
  },
  methods: {
    async loadTemplates() {
      const { data } = await this.$axios.get(
        process.env.VUE_APP_API_URL + "/email-templates/api"
      );
      this.publicTemplates = data;
      this.loading = false;
      this.$emit("refreshed");
    },
    showPreviewModal(template) {
      this.$emit("preview", template);
    },
    showEditTemplate(template) {
      this.$emit("showEditTemplate", template);
    },
    showAddTemplate() {
      this.$emit("showAddTemplate");
    },
  },
  watch: {
    fetchTemplates(newVal, oldVal) {
      if (newVal) {
        this.loadTemplates();
      }
    },
  },
  created() {
    this.loadTemplates();
  },
};
</script>