<template>
  <div class="card bg-light h-100">
    <div class="card-body">
      <h5>Shortcodes</h5>
      <hr />
      <p class="alert alert-primary mt-0">
        <i class="far fa-info-circle me-1"></i>
        Shortcodes are special snippets of text that are
        automatically replaced with dynamic information.
      </p>
      <table class="table table-striped table-bordered">
        <thead>
          <th>Shortcode</th>
          <th>Replaced With</th>
        </thead>
        <tbody>
          <tr>
            <td>[my-name]</td>
            <td>Your full name</td>
          </tr>
          <tr>
            <td>[clinic-name]</td>
            <td>Your Clinic's name</td>
          </tr>
          <tr>
            <td>[clinic-email]</td>
            <td>Your Clinic's email</td>
          </tr>
          <tr>
            <td>[client-name]</td>
            <td>Client's full name</td>
          </tr>
          <tr>
            <td>[client-first-name]</td>
            <td>Client's first name</td>
          </tr>
          <tr v-if="type === 'appointment'">
            <td>[event-start]</td>
            <td>Start date / time</td>
          </tr>
          <tr v-if="type === 'order'">
            <td>[order-id]</td>
            <td>Order reference ID</td>
          </tr>
          <tr v-if="type === 'order'">
            <td>[order-title]</td>
            <td>Order title</td>
          </tr>
          <tr v-if="type === 'order'">
            <td>[order-description]</td>
            <td>Order description</td>
          </tr>
          <tr v-if="type === 'order'">
            <td>[order-items]</td>
            <td>Table summary of order items</td>
          </tr>
          <tr v-if="type === 'order'">
            <td>[order-total]</td>
            <td>Order retail total</td>
          </tr>
          <tr v-if="type === 'order'">
            <td>[order-shipping-address]</td>
            <td>Order specified shipping address</td>
          </tr>
          <tr v-if="type === 'file'">
            <td>[file-name]</td>
            <td>Name of file</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: ["type"],
};
</script>