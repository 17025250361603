<template>
  <div>
    <div
      class="card border-0 shadow-sm"
      style="border: 1px solid #020067 !important"
    >
      <div class="card-header">
        <span>New Email Template</span>
        <button
          class="btn btn-sm btn-light float-end"
          @click.prevent="cancelAddTemplate"
        >
          <i class="far fa-times me-1"></i>
          Cancel
        </button>
      </div>
      <div class="card-body pt-0">
        <form @submit.prevent="saveNewTemplate">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>Template Type</label>
                <select
                  class="form-control"
                  v-model="newTemplate.type"
                  required
                >
                  <option disabled selected value="">
                    Select template type
                  </option>
                  <option value="appointment">Appointment</option>
                  <option value="client">Client</option>
                  <option value="file">File</option>
                  <option value="order">Order</option>
                </select>
                <small class="form-text text-muted">
                  This determines where the template can be used, and which data
                  is available via shortcodes.
                </small>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-7">
              <div>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Template Name</label>
                      <input
                        type="text"
                        v-model="newTemplate.title"
                        class="form-control"
                        placeholder="Enter a name..."
                        required
                      />
                      <small class="form-text text-muted">
                        This is what the template will be named in the your
                        marketing suite.
                      </small>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Email Subject</label>
                      <input
                        type="text"
                        v-model="newTemplate.email_subject"
                        class="form-control"
                        placeholder="Enter a subject..."
                        required
                      />
                      <small class="form-text text-muted">
                        This is the email subject when sent to clients.
                      </small>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label>Email Body</label>
                      <vue-editor
                        class="email-template-editor"
                        v-model="newTemplate.email_body"
                        required
                      ></vue-editor>
                      <small class="form-text text-muted">
                        This is the email body content when sent to clients.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-5 pb-3">
              <short-codes :type="newTemplate.type"></short-codes>
            </div>
          </div>
          <div class="row">
            <div class="col text-end">
              <button
                class="btn btn-sm btn-light me-2"
                @click.prevent="cancelAddTemplate"
              >
                <i class="far fa-times me-1"></i>
                Cancel
              </button>
              <button
                class="btn btn-outline-primary me-2"
                @click.prevent="showPreviewModal"
              >
                <i class="far fa-eye me-1"></i>
                Preview
              </button>
              <button class="btn btn-primary">
                <i class="far fa-save me-1"></i>
                Save Email Template
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import ShortCodes from "./ShortCodes";
export default {
  props: ["path", "user"],
  data() {
    return {
      newTemplate: {
        type: "",
      },
    };
  },
  methods: {
    saveNewTemplate() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL + "/email-templates/api",
          this.newTemplate
        )
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$emit("saved");
          this.$emit("close");
        });
    },
    cancelAddTemplate() {
      this.$emit("close");
    },
    showPreviewModal() {
      this.$emit("preview", this.newTemplate);
    },
  },
  mounted() {},
  components: {
    ShortCodes,
    VueEditor,
  },
};
</script>