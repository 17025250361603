<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-3 spark-settings-tabs">
        <marketing-nav></marketing-nav>
      </div>

      <!-- Tab cards -->
      <div class="col-md-9 ps-0">
        <busy :visible="loading" />
        <div v-if="!loading">
          <div class="row mb-3" id="templates">
            <div class="col">
              <add-template
                v-if="showAddTemplate"
                @close="showAddTemplate = false"
                @saved="fetchTemplates = true"
                @preview="showPreviewModal"
              ></add-template>

              <edit-template
                v-if="editTemplate"
                :template="editTemplate"
                @close="editTemplate = null"
                @saved="fetchTemplates = true"
                @preview="showPreviewModal"
              ></edit-template>

              <template-list
                v-if="!showAddTemplate && !editTemplate"
                @showAddTemplate="showAddTemplate = true"
                @showEditTemplate="showEditTemplate"
                @preview="showPreviewModal"
                @refreshed="fetchTemplates = false"
                :fetchTemplates="fetchTemplates"
              ></template-list>

              <template-preview-modal
                :template="previewTemplate"
                :user="user"
              ></template-preview-modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MarketingNav from "../../partials/MarketingNav";
import AddTemplate from "./templates-partials/AddTemplate";
import TemplateList from "./templates-partials/TemplateList";
import TemplatePreviewModal from "./templates-partials/TemplatePreviewModal";
import EditTemplate from "./templates-partials/EditTemplate";

export default {
  props: [],
  data() {
    return {
      loading: true,
      emailSettings: null,

      showAddTemplate: false,
      previewTemplate: null,
      editTemplate: null,
      fetchTemplates: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
    path() {
      return this.$router.currentRoute.fullPath;
    },
  },
  methods: {
    showEditTemplate(template) {
      this.editTemplate = template;
    },
    showPreviewModal(template) {
      this.previewTemplate = template;
      $("#previewModal").modal("show");
      $("#previewModal").modal("show");
    },
    fetchEmailSettings() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/marketing/api/" +
            this.user.id +
            "/email-settings"
        )
        .then(({ data }) => {
          this.emailSettings = data;
          this.loading = false;
        });
    },
  },
  mounted() {
    this.fetchEmailSettings();
  },
  components: {
    MarketingNav,
    EditTemplate,
    TemplatePreviewModal,
    TemplateList,
    AddTemplate,
  },
};
</script>